@import url('https://fonts.googleapis.com/css2?family=Antonio&display=swap');

// site will look VERY different if we add this, but we need to at some point as the above forces all regular fonts to 700 weight due to missing weights for Lato.
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

%container{
	width: 85%;
	margin: 0 auto;
}

// Default vars
$lato: 'Lato', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$dark-grey: #333333;
$white-grey: #f5f5f5;
$light-grey: #d8d8d8;
$lighter-grey: #bbb;
$honda-red: #f51638;
$honda-green: #1bc200;
$bg: #332f35;
$black: #000;
$fg: lighten($bg,20%);
$borderWidth: 1px;
$control-height: 40px;
$control-width: 80px;
$gutter-height: 16px;

//Custom vars

$primary_default_button: #E61F35;
$primary_navigation_backgroundColor:#333333;
$primary_font_color: #F5F5F5;